// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import "bootstrap";
import "slick-carousel";

// custom

jQuery(".home_slide").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    autoplaySpeed: 3000,
    variableWidth: false,
    responsive: [{
        breakpoint: 767,
        settings: {
            arrows: true,
            dots: false,
        }
    }]
});
